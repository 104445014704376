export enum ErrorType {
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  UNAVAILABLE = 'UNAVAILABLE',
  CLIENT_RESPONSIBLE = 'CLIENT_RESPONSIBLE',
  VALIDATION = 'VALIDATION',
  UNKNOWN = 'UNKNOWN',
  IGNORED = 'IGNORED',
}

export enum MemoryEnum {
  'Ei' = 'Ei',
  'Pi' = 'Pi',
  'Ti' = 'Ti',
  'Gi' = 'Gi',
  'Mi' = 'Mi',
  'Ki' = 'Ki',
}

export enum QueryKeys {
  DOMAINS = 'DOMAINS',
  REMOTE_DOMAINS = 'REMOTE_DOMAINS',
  PARTICIPANTS = 'PARTICIPANTS',
  REMOTE_PARTICIPANTS = 'REMOTE_PARTICIPANTS',
  NAVIGATORS = 'NAVIGATORS',
  DARS = 'DARS',
  USERS = 'USERS',
  APPLICATIONS = 'APPLICATIONS',
  FILES = 'FILES',
  CONTRACTS = 'CONTRACTS',
  COMMANDS = 'COMMANDS',
  DASHBOARD = 'DASHBOARD',
  HEALTH_DUMP_FILE = 'HEALTH_DUMP_FILE',
  VALIDATORS = 'VALIDATORS',
}

// TODO
export enum EventType {
  'addParticipant' = 'ADDED Participant',
  'removeParticipant' = 'DELETED Participant',
  'addDomain' = 'ADDED Domain',
  'removeDomain' = 'DELETED Domain',
}

export enum RightType {
  CanActAs = 'CanActAs',
  CanReadAs = 'CanReadAs',
  ParticipantAdmin = 'ParticipantAdmin',
}
