/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LogLineDto = {
    content: string;
    level?: LogLineDto.level;
    timeStamp: string;
};

export namespace LogLineDto {

    export enum level {
        TRACE = 'TRACE',
        DEBUG = 'DEBUG',
        INFO = 'INFO',
        WARN = 'WARN',
        ERROR = 'ERROR',
    }


}

