import { ParticipantDto } from 'data';
import { type InitParticipantDto } from 'app/components/participants/create';

export { setupEventSource } from './setup-event-source';
export * from './dars';
export { downloadFileFromCollection, download } from './download';
export * from './transactions-history';
export * from './date-parsers';
export * from './check-enterprise-cron';
export * from './resource-updaters';
export * from './capitalize';
export * from './storage-updaters';
export * from './select-input-adapters';
export * from './is-empty';
export * from './create-nested-object';

export const formatDate = (
  date: Date,
  locale = 'en-US',
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
): string => {
  const formatter = new Intl.DateTimeFormat(locale, options);
  return formatter.format(date);
};

export const secondsToDHMS = (sec: string) => {
  const seconds = Number(sec);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';

  if (d > 0) {
    return dDisplay + hDisplay + mDisplay;
  }

  return hDisplay + mDisplay + sDisplay;
};

export const handleIntegerInput = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/[0-9]|\./.test(keyValue)) return;
  e.preventDefault();
};

export const handleCpuResourcesInput = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/[0-9]|\.|m/.test(keyValue)) return;
  e.preventDefault();
};

export const prepareEnvVarsToRequest = (
  envVars: InitParticipantDto['envVars'] = [],
  postgresVars: InitParticipantDto['envVars'] = [],
): ParticipantDto['envVars'] => [...postgresVars, ...envVars];

export const prepareEnvVarsToForm = (
  envVars?: ParticipantDto['envVars'],
):
  | {
      preparedEnvVars: InitParticipantDto['envVars'];
      postgresVars: InitParticipantDto['envVars'];
    }
  | undefined => {
  if (!envVars) return envVars;

  const postgresVarRegExp = /\b(POSTGRES_USER|POSTGRES_PASSWORD)\b/;

  return {
    preparedEnvVars: envVars.filter((env) => !postgresVarRegExp.test(env.name as string)),
    postgresVars: envVars.filter((env) => postgresVarRegExp.test(env.name as string)),
  };
};

export const formatBooleanForDetailsPage = (v?: boolean) => (v ? 'Enabled' : 'Disabled');
